.input-container {
  width: 100%;
}

.input {
  height: 3rem;
  border: 1px solid #bababa;
  border-radius: calc(5rem / 20);
  padding: 1rem;
  font-size: 1rem;
  color: white;
  width: 100%;
}

.input--transparent {
  background: transparent !important;
}

.desc {
  color: white;
  height: auto;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
}
