.myOrderList--container {
  position: relative;
  border: 1px solid #bababa;
  border-radius: calc(5rem / 20);
  overflow-x: auto;
  width: 100%;
}

.myOrderList {
  border-collapse: separate;
  border-spacing: 0 5px;
  padding: 0 0.5rem;
  * {
    color: white;
    border-collapse: separate;
    font-size: small;
  }
  th:nth-of-type(1),
  td:nth-of-type(1) {
    padding-left: 0.5rem;
    width: 2rem;
  }
  th:nth-of-type(2),
  td:nth-of-type(2) {
    width: 3rem;
  }
  th:nth-of-type(3),
  td:nth-of-type(3) {
    width: 12rem;
  }
  th:nth-of-type(4),
  td:nth-of-type(4) {
    width: 9.5rem;
  }
  th:nth-of-type(5),
  td:nth-of-type(5) {
    width: 2rem;
  }
  th:nth-of-type(6),
  td:nth-of-type(6) {
    width: 4rem;
  }
  th:nth-of-type(7),
  td:nth-of-type(7) {
    width: 9.5rem;
  }
  th:nth-of-type(8),
  td:nth-of-type(8) {
    width: 3rem;
  }
  th:nth-of-type(9),
  td:nth-of-type(9) {
    width: 23rem;
  }
  th:nth-of-type(10),
  td:nth-of-type(10) {
    width: 6rem;
  }
  th:nth-of-type(11),
  td:nth-of-type(11) {
    width: 2rem;
  }
  th:nth-of-type(12),
  td:nth-of-type(12) {
    width: 5rem;
  }
  th:nth-of-type(13),
  td:nth-of-type(13) {
    width: 3rem;
  }
  th:nth-of-type(14),
  td:nth-of-type(14) {
    width: 4rem;
  }
  th:nth-of-type(15),
  td:nth-of-type(15) {
    width: 4rem;
  }

  thead tr th {
    border-bottom: 1px solid #bababa;
    padding-bottom: 0.5rem;
  }

  tbody {
    display: block;
    height: 24rem;
    overflow: auto;
    margin-bottom: 2rem;
  }
  thead,
  tbody tr,
  tfoot {
    display: table;
    width: 1269px;
    table-layout: fixed;
  }

  tbody::-webkit-autobar {
    display: none;
  }

  tfoot {
    position: absolute;
    bottom: 0;
    td:nth-of-type(1) {
      width: 74rem;
    }
    td:nth-of-type(2) {
      width: 2rem;
    }
    td:nth-of-type(3) {
      width: 5rem;
    }
    td:nth-of-type(4) {
      width: 11rem;
    }
  }

  tfoot tr td {
    border-top: 1px solid #bababa;
    padding-top: 0.5rem;
  }
}

.setNum {
  color: black;
  width: 2rem;
}

.paid {
  * {
    color: orange;
  }
}
