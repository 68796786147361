.button--container {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 0.3rem;
  * {
    color: #bababa;
  }
}

.button {
  width: 100%;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  font-weight: 100;
  color: dimgray;
}

.button--xxlarge {
  max-width: 11rem;
  min-width: 5rem;
  height: 6rem;
  min-height: 6rem;
  border-radius: calc(12rem / 30);
}

.button--xlarge {
  max-width: 7rem;
  min-width: 5rem;
  height: 6rem;
  min-height: 6rem;
  border-radius: calc(12rem / 30);
}

.button--large {
  width: 12rem;
  min-height: 3rem;
  border-radius: calc(12rem / 30);
}

.button--medium {
  width: 8rem;
  min-height: 2rem;
  font-size: 1rem;
  border-radius: calc(7.5rem / 20);
}

.button--small {
  width: 3rem;
  min-height: 1.5rem;
  font-size: 1rem;
  border-radius: calc(5rem / 20);
}

.button--long {
  width: 100%;
  min-height: 3rem;
  font-size: 1rem;
  border-radius: calc(5rem / 20);
}

.button--transparent {
  background: transparent;
  border: 1px solid #bababa;
  color: #bababa;
}

.button--full {
  max-width: unset;
  width: 100%;
}

.button--detail {
  color: dimgray;
}

.button--delete {
  color: white;
}

.button--icon {
  pointer-events: none;
}
