.MuiFormControl-root {
  width: 20rem;
  * {
    color: #bababa !important;
  }
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid #bababa !important;
}
